<template>
  <a-result>
    <template #icon>
      <a-icon type="clock-circle" theme="twoTone" />
    </template>
    <h1 slot="title" class="pt-3">
      {{ $t(`msg.shipment.exported.title`) }}
    </h1>
    <div slot="subTitle">
      <p class="font-size-18 text-secondary pt-2 pb-3">
        {{ $t('msg.shipment.exported.subtitle') }}
      </p>
    </div>
    <template #extra>
      <router-link :to="`/shipments`">
        <a-button
          type="primary"
          size="large">
          {{ $t('msg.shipment.exported.action1') }}
        </a-button>
      </router-link>
    </template>
  </a-result>
</template>

<script>
export default {
  name: 'ShipmentResult',
  props: ['result'],
}
</script>
